import rsp from "../../assets/rsp.webp";
import ravisp from "../../assets/ravisp.webp";
import vp from "../../assets/vp.webp";
import goi from "../../assets/goi.webp";
import omkar from "../../assets/omkar.webp";
import twitter from "../../assets/twitter.webp";
import peter from "../../assets/peter.webp";
import raman from "../../assets/raman.webp";
import peterq from "../../assets/peter-q.webp";
import ramanq from "../../assets/raman-q.webp";
import build from "../../assets/build.webp";
import res from "../../assets/res.webp";
import engage from "../../assets/engage.webp";
import design from "../../assets/design.webp";
import advise from "../../assets/advise.webp";
import gigavus from "../../assets/gigavus.webp";
import hydra from "../../assets/hydra.webp";
import chr from "../../assets/chr.webp";
import prox from "../../assets/prox.webp";
import bv from "../../assets/bv.webp";
import sfe from "../../assets/sfe.webp";
import sowmya from "../../assets/sowmiya.webp";
import ashok from "../../assets/ashok.webp";
import malika from "../../assets/malika.webp";
import aditi from "../../assets/aditi.webp";

// Who we are images
import chrway from "../../assets/whoweare/client-logos/channel.webp";
import synpulse from "../../assets/whoweare/client-logos/synpulse.webp";
import halbes from "../../assets/whoweare/client-logos/halbe.webp";
import nics from "../../assets/whoweare/client-logos/nic.webp";
import trustedSou from "../../assets/whoweare/client-logos/trusted-source.webp";
import boardVi from "../../assets/whoweare/client-logos/board-vision.webp";
import trustedSer from "../../assets/whoweare/client-logos/trusted-services.webp";
import smes from "../../assets/whoweare/client-logos/sme.webp";
import temasek from "../../assets/whoweare/client-logos/temasek.webp";
import great from "../../assets/whoweare/client-logos/great-eastern.webp";
import tms from "../../assets/whoweare/client-logos/tms.webp";
import mandal from "../../assets/whoweare/client-logos/mandal.webp";
import koufu from "../../assets/whoweare/client-logos/koufu.webp";
import ensign from "../../assets/whoweare/client-logos/ensign.webp";
import st from "../../assets/whoweare/client-logos/st.webp";
import citygas from "../../assets/whoweare/client-logos/citygas.webp";
import railtel from "../../assets/whoweare/client-logos/railtel.webp";
import govt from "../../assets/whoweare/client-logos/govt.webp";
import vertex from "../../assets/whoweare/client-logos/vertex.webp";
import six from "../../assets/whoweare/client-logos/six.webp";
import proxt from "../../assets/whoweare/client-logos/proxtera.webp";
import ssw from "../../assets/whoweare/client-logos/ssw.webp";

// Careers
import suresh from "../../assets/careers/emp/suresh.png";
import samantha from "../../assets/careers/emp/samantha.png";
import ashokhr from "../../assets/careers/emp/ashok.png";
import deepak from "../../assets/careers/emp/deepak.png";
import ponmalar from "../../assets/careers/emp/ponmalar.png";
import arun from "../../assets/careers/emp/arun.png";
import jp from "../../assets/careers/emp/jp.png";
import all from "../../assets/careers/all.webp";
import channelrunway from "../../assets/careers/channelrunway.webp";
import lunch from "../../assets/careers/lunch.webp";

export const JobListData = [
  // {
  //   id: 1,
  //   jobCategory: "Developers",
  //   slug: "flutter-developer",
  //   title: "Flutter Developer",
  //   description:
  //     "Instrive is a Chennai-based start-up that provides high quality Dev-ops solutions...",
  //   location: "Chennai",
  //   workType: "Full Time",
  //   experience: "3 - 5 years",

  //   jobAbout:
  //     '<p>Instrive is a Chennai-based start-up that provides high quality Dev-ops solutions for clients worldwide. In this increasingly digital world, we strive to solve problems and create value. Instrive was founded with one goal in mind, creating high-quality products and delivering great user experiences worldwide.</p><p>A number of products are being developed for both inhouse purposes and for our overseas clients, and we are actively expanding our team of talented professionals.</p> <p>As part of our ambitious product roadmap, we need flutter developers for our team.</p> <p>Your primary responsibility will be to develop flutter applications both (mobile and web) with a heavy focus on real time communication such as WebRTC, websockets etc.<p>You’ll make a meaningful impact and create lasting connections with our outstanding team, no matter where your career takes you.</p>',

  //   responsibility:
  //     "<ul><li>●&nbsp;&nbsp;&nbsp;Develop, test, and deploy fast and scalable Mobile, Web and Desktop apps.<li><li>●&nbsp;&nbsp;&nbsp;Lead the development of flutter applications.<li><li>●&nbsp;&nbsp;&nbsp;Identify production and non-production application issues as a part of deployment.<li><li>●&nbsp;&nbsp;&nbsp;Establish, refine and integrate development environment tools and software as needed.<li><li>●&nbsp;&nbsp;&nbsp;Building future-proof front-end libraries and reusable widgets.<li><li>●&nbsp;&nbsp;&nbsp;Knowledge in coding architecture.<li><li>●&nbsp;&nbsp;&nbsp;Collaboration with IT team, researchers, designers for designing robust apps and encouraging business goals.<li><li>●&nbsp;&nbsp;&nbsp;Create Unit and Integration tests to ensure the quality of code.<li><li>●&nbsp;&nbsp;&nbsp;Testing applications and fixing bugs, along with security and data protection features.<li><li>●&nbsp;&nbsp;&nbsp;Participate with developers to integration of REST APIs.<li><li>●&nbsp;&nbsp;&nbsp;Conducting code reviews of peer developers<li></ul>",

  //   skills:
  //     "<ul><li>●&nbsp;&nbsp;&nbsp;Bachelor’s degree in computer science, computer engineering, or similar.</li><li>●&nbsp;&nbsp;&nbsp;Previous work experience as a Flutter developer for a minimum of 3 years.</li><li>●&nbsp;&nbsp;&nbsp;Strong hands-on experience with MBaaS systems such as Firebase.</li><li>●&nbsp;&nbsp;&nbsp;Must have strong knowledge in MVC and/or MVVM framework.</li><li>●&nbsp;&nbsp;&nbsp;An understanding of DART and its core principles.</li><li>●&nbsp;&nbsp;&nbsp;Knowledge of GIT, JSON, design framework, web services.</li><li>●&nbsp;&nbsp;&nbsp;Good understanding in State managements, such as BLOC / GetX.</li><li>●&nbsp;&nbsp;&nbsp;A robust understanding of MUI and how best to use the inbuilt functionality provided.</li><li>●&nbsp;&nbsp;&nbsp;A hands-on understanding of how to create responsive designs.</li><li>●&nbsp;&nbsp;&nbsp;An excellent understanding of emerging trends in visual design and user experience.</li><li>●&nbsp;&nbsp;&nbsp;Experience in application architecture and publishing apps.</li><li>●&nbsp;&nbsp;&nbsp;Understanding Agile methodologies, client-side and server-side procedures</li></ul>",

  //   goodtohave:
  //     "<ul><li>●&nbsp;&nbsp;&nbsp;Knowledge of HTML5 and its best practices</li><li>●&nbsp;&nbsp;&nbsp;Cloud technology knowledge is a plus.</li><li>●&nbsp;&nbsp;&nbsp;Figma experience is a plus.</li><li>●&nbsp;&nbsp;&nbsp;Experience in native Android/IOS app development.</li></ul",

  //   softskills:
  //     "<ul><li>●&nbsp;&nbsp;&nbsp;Good communication skills.</li><li>●&nbsp;&nbsp;&nbsp;Critical thinking and time management.</li><li>●&nbsp;&nbsp;&nbsp;Good team player.</li><li>●&nbsp;&nbsp;&nbsp;Strong problem-solving and analytical skills.</li></li><li>●&nbsp;&nbsp;&nbsp;Collaboration to support business goals.</li></li><li>●&nbsp;&nbsp;&nbsp;Competence for individual contribution.</li></ul",
  // },
  {
    id: 2,
    jobCategory: "Developers",
    slug: "mern-stack",
    title: "Full Stack Engineer - MERN STACK",
    description:
      "Instrive is a Chennai-based start-up that provides high quality Dev-ops solutions...",
    location: "Chennai",
    workType: "Full Time",
    experience: "5 - 10 years",

    jobAbout:
      '<p>Instrive is a Chennai-based start-up that provides high quality Dev-ops solutions for clients worldwide. In this increasingly digital world, we strive to solve problems and create value. Instrive was founded with one goal in mind, creating high-quality products and delivering great user experiences worldwide.</p><p>A number of products are being developed for both inhouse purposes and for our overseas clients, and we are actively expanding our team of talented professionals.</p> <p>As part of our ambitious product roadmap, we need flutter developers for our team.</p> <p>Your primary responsibility will be to develop flutter applications both (mobile and web) with a heavy focus on real time communication such as WebRTC, websockets etc.<p>By working at the intersection of product, strategy, and technology, you will learn how to make strategic decisions, prioritise product features against business objectives, and put the user first.</p><p>You will make a meaningful impact and create lasting connections with our outstanding team, no matter where your career takes you.</p>',

    responsibility:
      "<ul><li>●&nbsp;&nbsp;&nbsp;Develop, test, and deploy fast and scalable web apps.<li><li>●&nbsp;&nbsp;&nbsp;Designing and maintenance of fully functional large relational and non-relational databases.<li><li>●&nbsp;&nbsp;&nbsp;Identify production and non-production application issues as a part of deployment.<li><li>●&nbsp;&nbsp;&nbsp;Deploy applications on AWS cloud with ability to quickly debug hosting related issues.<li><li>●&nbsp;&nbsp;&nbsp;Establish, refine and integrate development environment tools and software as needed.<li><li>●&nbsp;&nbsp;&nbsp;Knowledge in coding architecture for frontend and backend.<li><li>●&nbsp;&nbsp;&nbsp;Collaboration with IT team, researchers, designers for designing robust apps and encouraging business goals.<li><li>●&nbsp;&nbsp;&nbsp;Create Unit and Integration tests to ensure the quality of code.<li><li>●&nbsp;&nbsp;&nbsp;Testing applications and fixing bugs, along with security and data protection features.<li><li>●&nbsp;&nbsp;&nbsp;Establish code architecture decisions for supporting scalability and good performance.<li><li>●&nbsp;&nbsp;&nbsp;Make use of popular front-end frameworks like Bootstrap, Angular Material, etc and design UI components.<li><li>●&nbsp;&nbsp;&nbsp;Participation with developers for the creation of scalable REST APIs.<li><li>●&nbsp;&nbsp;&nbsp;Conducting code reviews of peer developers.<li></ul>",

    skills:
      "<ul><li>●&nbsp;&nbsp;&nbsp;Bachelor’s degree in computer science, computer engineering, or similar.</li><li>●&nbsp;&nbsp;&nbsp;Previous work experience as an MERN Stack developer for a minimum of 5 years.</li><li>●&nbsp;&nbsp;&nbsp;Expertise and experience in the four main technologies – MongoDB, ExpressJS, React, and NodeJS and D3.</li><li>●&nbsp;&nbsp;&nbsp;Redux React.js workflows, React hooks experience.</li><li>●&nbsp;&nbsp;&nbsp;Knowledge of HTML/CSS, JavaScript, AWS, GIT, and design framework, web services, JSON, frontend CSS frameworks like Bootstrap, Material etc.</li><li>●&nbsp;&nbsp;&nbsp;A robust understanding of MUI and how best to use the inbuilt functionality provided</li><li>●&nbsp;&nbsp;&nbsp;Strong knowledge of automated build and testing tools for UI components, such as Jest/Mocha/Jasmine.</li><li>●&nbsp;&nbsp;&nbsp;High-quality programming skills for a robust design.</li><li>●&nbsp;&nbsp;&nbsp;Experience in application architecture, server management, cross-browser compatibility, responsive design, and website performance.</li><li>●&nbsp;&nbsp;&nbsp;Understanding of DB architecture design and programming templates, Agile methodologies, client-side and server-side procedures.</li><li>●&nbsp;&nbsp;&nbsp;Server management skills</li></ul>",

    goodtohave:
      "<ul><li>●&nbsp;&nbsp;&nbsp;Good understanding of overall web design including basic usability.</li><li>●&nbsp;&nbsp;&nbsp;Accessibility, industry standards, architecture, and navigation.</li><li>●&nbsp;&nbsp;&nbsp;Certified SCRUM Master.</li><li>●&nbsp;&nbsp;&nbsp;Portfolio of work preferred. Include examples of all areas of interaction.</li><li>●&nbsp;&nbsp;&nbsp;Design knowledge (user flows, wireframes, and final graphical display).</li><li>●&nbsp;&nbsp;&nbsp;Developing for mobile platforms.</li><li>●&nbsp;&nbsp;&nbsp;Consumer Web Development Experience for High-Traffic, Public Facing web applications.</li><li>●&nbsp;&nbsp;&nbsp;Having knowledge of any test automation tool such as Selenium or Cucumber.</li><li>●&nbsp;&nbsp;&nbsp;Kibana, Splunk, Dynatrace, and other monitoring tools are a plus.</li></ul",

    softskills:
      "<ul><li>●&nbsp;&nbsp;&nbsp;Good communication skills.</li><li>●&nbsp;&nbsp;&nbsp;Critical thinking and time management.</li><li>●&nbsp;&nbsp;&nbsp;Good team player.</li><li>●&nbsp;&nbsp;&nbsp;Strong problem-solving and analytical skills.</li></li><li>●&nbsp;&nbsp;&nbsp;Collaboration to support business goals.</li></li><li>●&nbsp;&nbsp;&nbsp;Competence for individual contribution.</li></ul",
  },
  {
    id: 3,
    jobCategory: "Developers",
    slug: "senior-react",
    title: "Senior React Developer",
    description:
      "Instrive is a Chennai-based start-up that provides high quality Dev-ops solutions...",
    location: "Chennai",
    workType: "Full Time",
    experience: "5 - 10 years",

    jobAbout:
      '<p>Instrive is a Chennai-based start-up that provides high quality Dev-ops solutions for clients worldwide. In this increasingly digital world, we strive to solve problems and create value. Instrive was founded with one goal in mind, creating high-quality products and delivering great user experiences worldwide.</p><p>A number of products are being developed for both inhouse purposes and for our overseas clients, and we are actively expanding our team of talented professionals.</p> <p>As part of our ambitious product roadmap, we need flutter developers for our team.</p> <p>Your primary responsibility will be to develop flutter applications both (mobile and web) with a heavy focus on real time communication such as WebRTC, websockets etc.<p>By working at the intersection of product, strategy, and technology, you will learn how to make strategic decisions, prioritise product features against business objectives, and put the user first.</p><p>You will make a meaningful impact and create lasting connections with our outstanding team, no matter where your career takes you.</p>',

    responsibility:
      "<ul><li>●&nbsp;&nbsp;&nbsp;Develop new user-facing features using React.js and TypeScript.</li><li>●&nbsp;&nbsp;&nbsp;Build reusable components and front-end libraries for future use.</li><li>●&nbsp;&nbsp;&nbsp;Translate designs and wireframes into high-quality code.</li><li>●&nbsp;&nbsp;&nbsp;Implement state management using Redux, Redux Toolkit, and Saga.</li><li>●&nbsp;&nbsp;&nbsp;Integrate RESTful APIs and third-party libraries, ensuring seamless data flow and performance.</li><li>●&nbsp;&nbsp;&nbsp;Develop and maintain forms using Formik and validation with Yup.</li><li>●&nbsp;&nbsp;&nbsp;Implement modern styling solutions using Material UI and TailwindCSS.</li><li>●&nbsp;&nbsp;&nbsp;Write and maintain unit tests using React Testing Library (Jest) to ensure code quality.</li><li>●&nbsp;&nbsp;&nbsp;Collaborate with back-end developers, UI/UX designers, and the broader team to ensure seamless integration and high-performance applications.</li><li>●&nbsp;&nbsp;&nbsp;Ensure code quality by participating in code reviews and following best practices in front-end development.</li><li>●&nbsp;&nbsp;&nbsp;Work with OAuth and other authentication/authorization frameworks to secure applications.</li><li>●&nbsp;&nbsp;&nbsp;Utilize AWS services for hosting, storage, and deployment of front-end applications.</li><li>●&nbsp;&nbsp;&nbsp;Stay updated with the latest trends and technologies in front-end development.</li></ul>",

    // skills:
    //   "<ul><li>●&nbsp;&nbsp;&nbsp;Bachelor’s degree in computer science, computer engineering, or similar.</li><li>●&nbsp;&nbsp;&nbsp;Previous work experience as an MERN Stack developer for a minimum of 5 years.</li><li>●&nbsp;&nbsp;&nbsp;Expertise and experience in the four main technologies – MongoDB, ExpressJS, React, and NodeJS and D3.</li><li>●&nbsp;&nbsp;&nbsp;Redux React.js workflows, React hooks experience.</li><li>●&nbsp;&nbsp;&nbsp;Knowledge of HTML/CSS, JavaScript, AWS, GIT, and design framework, web services, JSON, frontend CSS frameworks like Bootstrap, Material etc.</li><li>●&nbsp;&nbsp;&nbsp;A robust understanding of MUI and how best to use the inbuilt functionality provided</li><li>●&nbsp;&nbsp;&nbsp;Strong knowledge of automated build and testing tools for UI components, such as Jest/Mocha/Jasmine.</li><li>●&nbsp;&nbsp;&nbsp;High-quality programming skills for a robust design.</li><li>●&nbsp;&nbsp;&nbsp;Experience in application architecture, server management, cross-browser compatibility, responsive design, and website performance.</li><li>●&nbsp;&nbsp;&nbsp;Understanding of DB architecture design and programming templates, Agile methodologies, client-side and server-side procedures.</li><li>●&nbsp;&nbsp;&nbsp;Server management skills</li></ul>",

    goodtohave:
      "<ul><li>●&nbsp;&nbsp;&nbsp;Bachelor’s degree in Computer Science, Engineering, or a related field, or equivalent work experience.</li><li>●&nbsp;&nbsp;&nbsp;5+ years of professional experience in front-end development with a focus on React.js.</li><li>●&nbsp;&nbsp;&nbsp;Proficiency in TypeScript and JavaScript ES6.</li><li>●&nbsp;&nbsp;&nbsp;Must be Proficient in multi-language support.</li><li>●&nbsp;&nbsp;&nbsp;Strong experience with Redux, Redux Toolkit, and Saga for state management.</li><li>●&nbsp;&nbsp;&nbsp;Experience with React Testing Library (Jest) for unit testing.</li><li>●&nbsp;&nbsp;&nbsp;Proficient in creating responsive and user-friendly interfaces using Material UI and TailwindCSS.</li><li>●&nbsp;&nbsp;&nbsp;Expertise in building and validating forms using Formik and Yup.</li><li>●&nbsp;&nbsp;&nbsp;Solid understanding of HTML and CSS.</li><li>●&nbsp;&nbsp;&nbsp;Experience with API integration and RESTful services.</li><li>●&nbsp;&nbsp;&nbsp;Knowledge of AWS services for cloud-based development and deployment.</li><li>●&nbsp;&nbsp;&nbsp;Familiarity with OAuth and other security frameworks for authentication and authorization.</li><li>●&nbsp;&nbsp;&nbsp;Strong problem-solving skills and attention to detail.</li><li>●&nbsp;&nbsp;&nbsp;Excellent communication and teamwork skill</li></ul>",

    softskills:
      "<ul><li>●&nbsp;&nbsp;&nbsp;Good communication skills.</li><li>●&nbsp;&nbsp;&nbsp;Critical thinking and time management.</li><li>●&nbsp;&nbsp;&nbsp;Good team player.</li><li>●&nbsp;&nbsp;&nbsp;Strong problem-solving and analytical skills.</li></li><li>●&nbsp;&nbsp;&nbsp;Collaboration to support business goals.</li></li><li>●&nbsp;&nbsp;&nbsp;Competence for individual contribution.</li></ul",
  },
  // {
  //   id: 4,
  //   jobCategory: "Developers",
  //   slug: "project-manager",
  //   title: "Senior Project Manager",
  //   description:
  //     "Instrive is a Chennai-based start-up that provides high quality Dev-ops solutions...",
  //   location: "Chennai",
  //   workType: "Full Time",
  //   experience: "4 - 6 years",

  //   jobAbout:
  //     '<p>Instrive is a Chennai-based start-up that provides high quality Dev-ops solutions for clients worldwide. In this increasingly digital world, we strive to solve problems and create value. Instrive was founded with one goal in mind, creating high-quality products and delivering great user experiences worldwide.</p><p>Hydrameet is India’s best-in-class homegrown video conferencing platform recognized by the Indian government <a href="https://hydrameet.com" target="_blank">(https://hydrameet.com)</a>.</p> <p>A number of products are being developed for both inhouse purposes and for our overseas clients, and we are actively expanding our team of talented professionals.</p>By working at the intersection of product, strategy, and technology, you will learn how to make strategic decisions, prioritise product features against business objectives, and put the user first.</p><p>You will make a meaningful impact and create lasting connections with our outstanding team, no matter where your career takes you.</p>',

  //   responsibility:
  //     "<ul><li>●&nbsp;&nbsp;&nbsp;Manage end-to-end software development projects, ensuring successful delivery within agreed timelines, scope and budget.<li><li>●&nbsp;&nbsp;&nbsp;Coordinate project activities, including planning, scheduling, resource allocation and task delegation.<li><li>●&nbsp;&nbsp;&nbsp;Collaborate closely with cross-functional teams, including developers, designers, quality assurance and stakeholders, to ensure effective communication and seamless project execution.<li><li>●&nbsp;&nbsp;&nbsp;Conduct regular project status meetings, provide progress reports and address any issues or risks that may impact project delivery.<li><li>●&nbsp;&nbsp;&nbsp;Facilitate requirements gathering and ensure accurate documentation using JIRA and Confluence.<li><li>●&nbsp;&nbsp;&nbsp;Manage client expectations and maintain regular communication with overseas clients, fostering strong client relationships.<li><li>●&nbsp;&nbsp;&nbsp;Monitor project milestones and deliverables, tracking progress and ensuring timely resolution of any deviations.<li><li>●&nbsp;&nbsp;&nbsp;Identify and mitigate project risks, proactively addressing any potential roadblocks.<li><li>●&nbsp;&nbsp;&nbsp;Continuously improve project management processes and methodologies, striving for increased efficiency and productivity.<li><li>●&nbsp;&nbsp;&nbsp;Stay updated with industry trends and best practices in software project management, sharing knowledge with the team and implementing relevant improvements.<li></ul>",

  //   skills:
  //     "<ul><li>●&nbsp;&nbsp;&nbsp;Bachelor's degree in Computer Science, Software Engineering, or a related field.</li><li>●&nbsp;&nbsp;&nbsp;4-6 years of experience in software project management, specifically in the software development life cycle.</li><li>●&nbsp;&nbsp;&nbsp;Minimum 2 years of prior experience as a software developer.</li><li>●&nbsp;&nbsp;&nbsp;Proven experience in successfully managing and delivering software projects on time and within budget.</li><li>●&nbsp;&nbsp;&nbsp;Strong knowledge of project management methodologies, tools and techniques.</li><li>●&nbsp;&nbsp;&nbsp;Proficiency in using JIRA and Confluence for project planning, tracking and documentation.</li><li>●&nbsp;&nbsp;&nbsp;Excellent communication and interpersonal skills, with the ability to effectively collaborate with both technical and non-technical stakeholders.</li><li>●&nbsp;&nbsp;&nbsp;Experience working with overseas clients, particularly in the SEA and MENA regions, is highly desirable.</li><li>●&nbsp;&nbsp;&nbsp;Ability to adapt to changing priorities and manage multiple projects simultaneously.</li><li>●&nbsp;&nbsp;&nbsp;Strong analytical and problem-solving skills, with a keen attention to detail.</li><li>●&nbsp;&nbsp;&nbsp;PMP (Project Management Professional) certification is a plus</li><li>●&nbsp;&nbsp;&nbsp;Agile or Scrum certification is a plus.</li><li><br/>If you meet the qualifications and are eager to take on a challenging role in software project management, where excellent communication skills are a must, we would love to hear from you. Please submit your resume, along with a cover letter highlighting your relevant experience and achievements.</li></ul>",

  // goodtohave:
  //   "<ul><li>●&nbsp;&nbsp;&nbsp;Good understanding of overall web design including basic usability.</li><li>●&nbsp;&nbsp;&nbsp;Accessibility, industry standards, architecture, and navigation.</li><li>●&nbsp;&nbsp;&nbsp;Certified SCRUM Master.</li><li>●&nbsp;&nbsp;&nbsp;Portfolio of work preferred. Include examples of all areas of interaction.</li><li>●&nbsp;&nbsp;&nbsp;Design knowledge (user flows, wireframes, and final graphical display).</li><li>●&nbsp;&nbsp;&nbsp;Developing for mobile platforms.</li><li>●&nbsp;&nbsp;&nbsp;Consumer Web Development Experience for High-Traffic, Public Facing web applications.</li><li>●&nbsp;&nbsp;&nbsp;Having knowledge of any test automation tool such as Selenium or Cucumber.</li><li>●&nbsp;&nbsp;&nbsp;Kibana, Splunk, Dynatrace, and other monitoring tools are a plus.</li></ul",

  // softskills:
  //   "<ul><li>●&nbsp;&nbsp;&nbsp;Good communication skills.</li><li>●&nbsp;&nbsp;&nbsp;Critical thinking and time management.</li><li>●&nbsp;&nbsp;&nbsp;Good team player.</li><li>●&nbsp;&nbsp;&nbsp;Strong problem-solving and analytical skills.</li></li><li>●&nbsp;&nbsp;&nbsp;Collaboration to support business goals.</li></li><li>●&nbsp;&nbsp;&nbsp;Competence for individual contribution.</li></ul",
  // },
  {
    id: 5,
    jobCategory: "Testers",
    slug: "software-tester",
    title: "Software Tester",
    description:
      "Instrive is a Chennai-based start-up that provides high quality Dev-ops solutions...",
    location: "Chennai",
    workType: "Full Time",
    experience: "3 - 5 years",

    jobAbout:
      '<p>Instrive is a Chennai-based start-up that provides high quality Dev-ops solutions for clients worldwide. In this increasingly digital world, we strive to solve problems and create value. Instrive was founded with one goal in mind, creating high-quality products and delivering great user experiences worldwide.</p><p>Hydrameet is India’s best-in-class homegrown video conferencing platform recognized by the Indian government <a href="https://hydrameet.com" target="_blank">(https://hydrameet.com)</a>.</p> <p></p>Several products are being developed for both in-house purposes and our overseas clients, and we are actively expanding our team of talented professionals.</p><p>We are constantly pushing the boundaries of what is possible, and our employees have the opportunity to be at the forefront of this innovation. Our flat organisational structure encourages open communication and idea-sharing and provides ample opportunities for employees to take ownership of their work and make a real impact.</p>',

    responsibility:
      "<ul><li>●&nbsp;&nbsp;&nbsp;Develop and execute test plans, test cases, and test scripts to ensure the quality of software applications.</li><li>●&nbsp;&nbsp;&nbsp;Collaborate with software developers, product managers, and other stakeholders to understand project requirements and design comprehensive test strategies.</li><li>●&nbsp;&nbsp;&nbsp;Conduct functional, regression, integration, and performance testing to identify software defects and ensure compliance with quality standards.</li><li>●&nbsp;&nbsp;&nbsp;Document and track software defects, utilizing bug tracking tools, and work closely with development teams to resolve issues in a timely manner.</li><li>●&nbsp;&nbsp;&nbsp;Perform exploratory testing to uncover hidden defects and improve overall product quality.</li><li>●&nbsp;&nbsp;&nbsp;Participate in the review and analysis of software requirements and specifications to provide valuable feedback and ensure testability.</li><li>●&nbsp;&nbsp;&nbsp;Stay updated with industry trends and best practices in software testing methodologies, tools, and techniques.</li><li>●&nbsp;&nbsp;&nbsp;Contribute to the continuous improvement of the software development process by suggesting enhancements to testing procedures and practices.</li><li>●&nbsp;&nbsp;&nbsp;Collaborate with cross-functional teams to ensure the successful delivery of high-quality software products within established timelines.</li></ul>",

    skills:
      "<ul><li>●&nbsp;&nbsp;&nbsp;Bachelor's degree.</li><li>●&nbsp;&nbsp;&nbsp;Minimum of 3 years of experience in software testing or quality assurance roles.</li><li>●&nbsp;&nbsp;&nbsp;Strong knowledge of software testing methodologies, tools, and techniques.</li><li>●&nbsp;&nbsp;&nbsp;Proficiency in writing clear, concise, and comprehensive test plans and test cases.</li><li>●&nbsp;&nbsp;&nbsp;Experience with automated testing tools and frameworks (e.g., Selenium, JUnit, TestNG) is preferred.</li><li>●&nbsp;&nbsp;&nbsp;Familiarity with programming languages such as Java, Python is a plus.</li><li>●&nbsp;&nbsp;&nbsp;Excellent analytical and problem-solving skills with a keen attention to detail.</li><li>●&nbsp;&nbsp;&nbsp;Strong communication and collaboration abilities with the ability to work effectively in a team environment.</li><li>●&nbsp;&nbsp;&nbsp;Self-motivated and proactive attitude with the ability to prioritize tasks and meet deadlines in a fast-paced environment.</li><ul>",

    goodtohave:
      "<ul><li>●&nbsp;&nbsp;&nbsp;Experience MEAN or MERN stack with Devops and Agile methodologies.</li><li>●&nbsp;&nbsp;&nbsp;Knowledge of cloud-based technologies and product design principles.</li></ul>"

    // softskills:
    //   "<ul><li>●&nbsp;&nbsp;&nbsp;Good communication skills.</li><li>●&nbsp;&nbsp;&nbsp;Critical thinking and time management.</li><li>●&nbsp;&nbsp;&nbsp;Good team player.</li><li>●&nbsp;&nbsp;&nbsp;Strong problem-solving and analytical skills.</li></li><li>●&nbsp;&nbsp;&nbsp;Collaboration to support business goals.</li></li><li>●&nbsp;&nbsp;&nbsp;Competence for individual contribution.</li></ul",
  },
  {
    id: 6,
    jobCategory: "Developers",
    slug: "technical-project-manager",
    title: "Technical Project Manager",
    description:
      "Instrive is a Chennai-based start-up that provides high quality Dev-ops solutions...",
    location: "Chennai",
    workType: "Full Time",
    experience: "7 - 10 years",

    jobAbout:
      '<p>Instrive is a Chennai-based start-up that provides high quality Dev-ops solutions for clients worldwide. In this increasingly digital world, we strive to solve problems and create value. Instrive was founded with one goal in mind, creating high-quality products and delivering great user experiences worldwide.</p><p>Hydrameet is India’s best-in-class homegrown video conferencing platform recognized by the Indian government <a href="https://hydrameet.com" target="_blank">(https://hydrameet.com)</a>.</p> <p></p>Several products are being developed for both in-house purposes and our overseas clients, and we are actively expanding our team of talented professionals.</p><p>We are constantly pushing the boundaries of what is possible, and our employees have the opportunity to be at the forefront of this innovation. Our flat organisational structure encourages open communication and idea-sharing and provides ample opportunities for employees to take ownership of their work and make a real impact.</p>',

    responsibility:
      "<ul><li>●&nbsp;&nbsp;&nbsp;Analyse technical requirements and work closely with stakeholders to translate them into actionable product features.</li><li>●&nbsp;&nbsp;&nbsp;Collaborate with engineering teams to define product architecture and ensure scalable and efficient design solutions.</li><li>●&nbsp;&nbsp;&nbsp;Lead and drive projects aimed at resolving mutual problems, ensuring alignment with company goals and timelines.</li><li>●&nbsp;&nbsp;&nbsp;Conduct comprehensive market research and competitor analysis to identify trends, inform product decisions, and maintain competitive advantage.</li><li>●&nbsp;&nbsp;&nbsp;Define and communicate the product vision, strategy, and roadmap that aligns with company objectives, ensuring all stakeholders are informed and on the same page.</li><li>●&nbsp;&nbsp;&nbsp;Oversee the entire product lifecycle, from ideation through launch, driving product enhancements, prioritising features, and ensuring timely delivery.</li><li>●&nbsp;&nbsp;&nbsp;Conduct project status meetings and provide regular status updates to senior management.</li><li>●&nbsp;&nbsp;&nbsp;Leverage engineering/technical background to understand technical challenges and provide valuable insights to the teams.</li><li>●&nbsp;&nbsp;&nbsp;Maintain a proactive, solution-oriented mindset.</li></ul>",

    skills:
      "<ul><li>●&nbsp;&nbsp;&nbsp;Minimum of 7 years proven experience as an Engineer or Technical Product Manager, with a successful track record in problem-solving and collaboration.</li><li>●&nbsp;&nbsp;&nbsp;Strong understanding of software development processes and methodologies.</li><li>●&nbsp;&nbsp;&nbsp;Proven ability to collaborate with cross-functional teams, including engineering, design, and product.</li><li>●&nbsp;&nbsp;&nbsp;Excellent communication skills, with the ability to articulate technical concepts to both technical and non-technical stakeholders.</li><li>●&nbsp;&nbsp;&nbsp;Experience in conducting market research and competitor analysis.</li><li>●&nbsp;&nbsp;&nbsp;Strong problem-solving and decision-making abilities.</li></ul>",

    // goodtohave:
    //   "<ul><li>●&nbsp;&nbsp;&nbsp;Experience MEAN or MERN stack with Devops and Agile methodologies.</li><li>●&nbsp;&nbsp;&nbsp;Knowledge of cloud-based technologies and product design principles.</li></ul>"

    // softskills:
    //   "<ul><li>●&nbsp;&nbsp;&nbsp;Good communication skills.</li><li>●&nbsp;&nbsp;&nbsp;Critical thinking and time management.</li><li>●&nbsp;&nbsp;&nbsp;Good team player.</li><li>●&nbsp;&nbsp;&nbsp;Strong problem-solving and analytical skills.</li></li><li>●&nbsp;&nbsp;&nbsp;Collaboration to support business goals.</li></li><li>●&nbsp;&nbsp;&nbsp;Competence for individual contribution.</li></ul",
  },
  // {
  //     id: 7,
  //     jobCategory: 'Developers',
  //     slug: 'user-experience-specialist',
  //     title: 'User Experience Specialist',
  //     description: 'Instrive is a Chennai-based start-up that provides high quality Dev-ops solutions...',
  //     location: 'Chennai',
  //     workType: 'Full Time',
  //     experience: '2 - 8 years',

  //     jobAbout: '<p>Instrive is a Chennai-based start-up that provides high quality Dev-ops solutions for clients worldwide. In this increasingly digital world, we strive to solve problems and create value. Instrive was founded with one goal in mind, creating high-quality products and delivering great user experiences worldwide.</p><p>Our product, Hydrameet is India’s best-in-class homegrown video conferencing platform recognized by the Indian government <a href="https://hydrameet.com" target="_blank">(https://hydrameet.com)</a>.</p> <p>A number of products are being developed for both inhouse purposes and for our overseas clients, and we are actively expanding our team of talented professionals.</p> <p>As part of our ambitious product roadmap, we need flutter developers for our team.</p> <p>Your primary responsibility will be to develop flutter applications both (mobile and web) with a heavy focus on real time communication such as WebRTC, websockets etc.</p>',

  //     responsibility: '<ul><li>●&nbsp;&nbsp;&nbsp;Lead the development of user interfaces by providing technical leadership and mentorship<li><li>●&nbsp;&nbsp;&nbsp;Bring your technical vision to life by prototyping creative solutions quickly and collaborating with others.<li><li>●&nbsp;&nbsp;&nbsp;Engage in standards-based, open solutions creation and consumption.<li><li>●&nbsp;&nbsp;&nbsp;Identify and implement opportunities for process and tool improvement.<li><li>●&nbsp;&nbsp;&nbsp;Assists in the creation of effective, usable and satisfying user experiences with information technology.<li><li>●&nbsp;&nbsp;&nbsp;Supports the performance of user-centered design activities.<li><li>●&nbsp;&nbsp;&nbsp;Assists with user research and in the definition of user requirements and usability goals. Supports the creation of user interaction design alternatives and the building of prototypes.<li><li>●&nbsp;&nbsp;&nbsp;Designing new user-facing features based on framework such as Material UI, Fabric UI for React.js<li><li>●&nbsp;&nbsp;&nbsp;Designing reusable components.<li><li>●&nbsp;&nbsp;&nbsp;Making components responsive to a vast array of web-capable devices and browsers.<li><li>●&nbsp;&nbsp;&nbsp;Develop UI and UX strategies based on our goals.<li><li>●&nbsp;&nbsp;&nbsp;Collaborate with our product and client side teams towards shared objective<li></ul>',

  //     skills: '<ul><li>●&nbsp;&nbsp;&nbsp;We don’t care about your educational qualifications.</li><li>●&nbsp;&nbsp;&nbsp;Experience developing user interfaces for at least five years</li><li>●&nbsp;&nbsp;&nbsp;Producing high quality UX design solutions through inception till Production.</li><li>●&nbsp;&nbsp;&nbsp;Basic hands-on experience in HTML5, CSS 3, NPM, React JS.</li><li>●&nbsp;&nbsp;&nbsp;Good understanding of React.js and its core principles</li><li>●&nbsp;&nbsp;&nbsp;A robust understanding of MUI and how best to use the inbuilt functionality provided</li><li>●&nbsp;&nbsp;&nbsp;A hands-on understanding of how to create responsive designs.</li><li>●&nbsp;&nbsp;&nbsp;An excellent understanding of emerging trends in visual design and user experience</li><li>●&nbsp;&nbsp;&nbsp;Testing framework experience (Jest/Mocha/Jasmine).</li><li>●&nbsp;&nbsp;&nbsp;Ability to communicate effectively verbally and in writing.</li><li>●&nbsp;&nbsp;&nbsp;Adaptability to rapid change</li></ul>',

  //     goodtohave: '<ul><li>●&nbsp;&nbsp;&nbsp;CSS, CSS extension languages (Less, Sass), and CSS preprocessor tools expertise.</li><li>●&nbsp;&nbsp;&nbsp;Knowledge of HTML5 and its best practices.</li><li>●&nbsp;&nbsp;&nbsp;Certified SCRUM Master.</li><li>●&nbsp;&nbsp;&nbsp;Having knowledge of any test automation tool such as Selenium or Cucumber.</li><li>●&nbsp;&nbsp;&nbsp;Cloud technology knowledge is a plus.</li></ul',
  // },
];

export const content = [
  {
    title: "Board.Vision",
    image: bv,
    desc: "Board.Vision is one of Singapore’s leading boardroom applications with a focus on governance and optimising the collaborative decision-making process.",
  },
  {
    title: "Gigavus",
    image: gigavus,
    desc: "Your passion can become a business with Gigavus. Turn Your Passion into a Gig, Today. Make your passion a micro-event business when you #GigWhatYouLove.",
  },
  {
    title: "Hydrameet",
    image: hydra,
    desc: "HydraMeet is a security-first, Video Conferencing Ecosystem awarded Innovation challenge runner-up by the Ministry of Electronics & Information, Government of India.",
  },
  {
    title: "SMEFE",
    image: sfe,
    desc: "The SME Financial Empowerment (SFE) programme builds foundational digital financial literacy for Micro, Small, Medium Enterpises across the world.",
  },
  {
    title: "Channelrunway",
    image: chr,
    desc: "Enabling the channel ecosystem - Driving growth & trust across the value chain through increased transparency & quality information.",
  },
  {
    title: "Proxtera",
    image: prox,
    desc: "One Connection To Reach The World Access a network of wholesale marketplaces and trade related digital services.",
  },
];

export const servicesImgs = [build, res, engage, design, advise];

export const testi_imgs = [
  {
    name: "Jagdeep Dhankar",
    image: vp,
    position: "Vice President of India",
    pic: twitter,
  },
  {
    name: "Ravi Shankar Prasad",
    image: ravisp,
    position: "Member of Parliament",
    pic: twitter,
  },
  {
    name: "RSPrasad Office",
    image: rsp,
    position: "Office of @rsprasad",
    pic: twitter,
  },
  {
    name: "Gov of India",
    image: goi,
    position: "Vice President of India",
    pic: twitter,
  },
  {
    name: "Dr Omkar Rai",
    image: omkar,
    position: "Executive Chairman, @Startup_Odisha",
    pic: twitter,
  },
  {
    name: "Peter Phan",
    image: peterq,
    position: "Senior Vice President at Trusted services",
    pic: peter,
  },
  {
    name: "Raman Madan",
    image: ramanq,
    position: "Co-Founder & Managing Partner at Channel Runway",
    pic: raman,
  },
];

export const personImgs = [ashok, malika, aditi, sowmya];

export const personNamesAndPosts = [
  {
    name: "Ashok Kannadasan",
    post: "Founder and CEO",
  },
  {
    name: "Malika Kamboj",
    post: "Branding and Marketing",
  },
  {
    name: "Aditi Shinde",
    post: "Product Design",
  },
  {
    name: "Sowmya Skandan",
    post: "People Operations",
  },
];

export const partnerLogos = [
  chrway,
  synpulse,
  halbes,
  nics,
  trustedSou,
  boardVi,
  trustedSer,
  ssw,
  smes,
  temasek,
  great,
  tms,
  mandal,
  koufu,
  ensign,
  st,
  citygas,
  railtel,
  govt,
  vertex,
  six,
  proxt,
];

export const employeesSays = [
  {
    data: "Excellent work-life balance with top-notch benefits. The management team really cares about their employees. And, there is no yelling and screaming about challenges, just collaboration and ideas. The office environment is very fun and expansive. It is a very challenging company due to the pace and ingenuity around every corner, which is awesome.",
    name: "Arun Prasad",
    post: "Principle Developer and Application Architect",
    pic: arun,
  },
  {
    data: "Instrive soft labs company is a good platform for freshers to start their careers. They can learn many things in a short period.",
    name: "Jaya Prakash",
    post: "Software Developer",
    pic: jp,
  },
  {
    data: "Instrive Soft Labs company is a good company to work for. Management people are very supportive. Its like a family company, they give respect to employees thoughts, opinions, and feedback. Everyone helps and supports each other to develop their career to the next level. And Instrive Softlabs gives all employees a good salary package with excellent motivation. Great place to learn, grow and work.",
    name: "Ashok Kumar",
    post: "HR",
    pic: ashokhr,
  },
  {
    data: "Instrive Soft Labs is good about the work culture, timings and learning base. Learning new things and upgrading skills is great here. There is constant exposure to other tools through the weekly presentation.",
    name: "Suresh Kumar",
    post: "Software Test Engineer",
    pic: suresh,
  },
  {
    data: "Peaceful working environment, guiding staff, Chance to get learn new technology",
    name: "Ponmalar",
    post: "Software Developer",
    pic: ponmalar,
  },
  {
    data: "Job security is good. Great compensation, benefits, company events, etc. They treat you like a person, not an employee. That is reflected in the benefits and how management approaches employees.",
    name: "Deepak Kumar",
    post: "Senior Software Developer",
    pic: deepak,
  },
  {
    data: "Great management that takes care of their people. Good Work-Life balance and an excellent place to learn, explore and grow.",
    name: "Samantha Sree",
    post: "Senior Test Engineer",
    pic: samantha,
  },
];

export const empImgs = [all, channelrunway, lunch];
